import React, { useEffect } from 'react';
import { getReindex } from '../administration.reducer';
export const ReindexPage = () => {

	useEffect(() => {
		getReindex();
		console.log("asd")
	}, []);



	return (
		<div>
			<h2 >Reindexing started
			</h2>
		</div>
	);
};

export default ReindexPage;