import './search.scss';

import React, { useState, useEffect } from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as componentUtility from "./componentUtility";
import { getMyInteractionContext } from '../../entities/interaction-context/interaction-context.reducer';


import * as ReactDOM from "react-dom";

/* eslint no-console: 0 */


export const JSONLDIcon = props => (
	<><a className="jsonld-icon" title="Access JSONLD representation of this example"  href={'/b/' + props?.b + '/ex/' + props?.ex + '.jsonld'} rel="noopener noreferrer" target="_jsonld">
		<img src="content/images/json-ld-data.png" alt="JSONLD Logo" width="24px" />&nbsp;&nbsp;&nbsp;
	</a>
	</>
);

export const PaperHiveIcon = props => (
	<a className="paperhive-icon" title="Collaborate on paperhive " href={'https://paperhive.org/documents/remote?type=langsci&id=' + props?.bid} target="_paperhive">
		<img src="content/images/paperhive.png" alt="Paperhive Logo" width="24px" />&nbsp;&nbsp;&nbsp;
	</a>
);
export const LangsciIcon = props => (
	<a className="langsci-icon" title="Go to book" href={ props?.bid} target="_langsci">
	{props?.bid.indexOf('langsci-press.org') >= 0 ?
		<img src="content/images/langsci.png" alt="Langsci Logo" width="24px" />:
		<img src="content/images/glossa.png" alt="glossa Logo" width="24px" height="24px"/>
		}&nbsp;&nbsp;&nbsp;
	</a>
);
import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;


// need to be set to all!!
const myLang = "all";
function ISSHitItem(props) {


	const { bemBlocks, result } = props
	const rawJson = host + "/_doc/" + result._id;


	const hitPopover = (
		<Popover id="popover-hit" key={result._source.ID + '123'} >
			<Popover.Title as="h3">Details</Popover.Title>
			<Popover.Content key={result._source.ID + '23'} >
				<h5 key={result._source.ID + '1'} >ID</h5>
				{result._source.ID}
				<h5 key={result._source.ID + '2'}>Sentence</h5>
				<div className="content" dangerouslySetInnerHTML={{ __html: result?._source?.html }}></div>
				<h5 key={result._source.ID + "3"}>Translation</h5>
				<div className="translation" >{result._source.trs} ({result._source.language})</div>
				<div className="language" >{componentUtility.langNameLinked(result._source, true)}</div>
				<h5 key={result._source.ID + "4"}>Clength</h5>
				{result._source.clength}
				<h5 key={result._source.ID + "5"}>Wlength</h5>
				{result._source.wlength}
				<h5 key={result._source.ID + "6"}>Categories</h5>
				{componentUtility.txt2list(result._source.categories)}
				{componentUtility.txt2HtmlTitled(result._source.citation, "Citation")}
				{componentUtility.bookLink(result._source)}
			</Popover.Content>
		</Popover>
	);






	return (<div key={result._id} >
		<OverlayTrigger trigger="hover" placement="right" overlay={hitPopover} rootClose>
			<div className="content" dangerouslySetInnerHTML={{ __html: result?._source?.html }}></div>
		</OverlayTrigger>
			<span className="ppper">{result._source.trs} ({result._source.language})</span>
		<br /> 
		<LangsciIcon bid={result?._source?.doc_URL} title={result?._source?.book_title} />
		{ result?._source?.provider==="langsci" ? <PaperHiveIcon bid={result?._source?.doc_ID} /> : ""}
		<JSONLDIcon b={result?._source?.doc_ID} ex={result?._source?.ID} />&nbsp;&nbsp;&nbsp;
		{componentUtility.langNameLinked(result._source, false)}&nbsp;&nbsp;&nbsp;
		<div className="citation">{componentUtility.citation(result._source)}</div>
		<br />
		<hr />
	</div>)


}
export default ISSHitItem;

