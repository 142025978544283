import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button } from 'reactstrap';
import generator from 'generate-password';
import { uniqueNamesGenerator, Config, adjectives, colors, names } from 'unique-names-generator';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset } from './register.reducer';

export interface IRegisterProps extends StateProps, DispatchProps { }

export const RegisterPage = (props: IRegisterProps) => {
	const [password, setPassword] = useState('');

	const randomName: string = uniqueNamesGenerator({
		dictionaries: [adjectives, names],
		separator: '_',
		style: 'lowerCase'
	});

	useEffect(
		() => () => {
			props.reset();

		},
		[]
	);
	let pw = undefined; pw = generator.generate({
		length: 10,
		numbers: true
	});
	console.info(pw);
	const handleValidSubmit = (event, values) => {

		props.handleRegister(values.username, values.email, values.firstPassword, props.currentLocale);
		event.preventDefault();
	};

	const updatePassword = event => setPassword(event.target.value);

	return (
		<div>
			<Row className="justify-content-center">
				<Col md="8">
					<h1 id="register-title">
						<Translate contentKey="register.title">Registration</Translate>
					</h1>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col md="8">
					<AvForm id="register-form" onValidSubmit={handleValidSubmit}>
						<AvField
							value={randomName}
							name="username"
							label={translate('global.form.username.label')}
							placeholder={translate('global.form.username.placeholder')}
							validate={{
								required: { value: true, errorMessage: translate('register.messages.validate.login.required') },
								pattern: {
									value: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
									errorMessage: translate('register.messages.validate.login.pattern'),
								},
								minLength: { value: 1, errorMessage: translate('register.messages.validate.login.minlength') },
								maxLength: { value: 50, errorMessage: translate('register.messages.validate.login.maxlength') },
							}}
						/>
						<AvField
							name="email"
							value={randomName + '@xitio.de'}
							label={translate('global.form.email.label')}
							placeholder={translate('global.form.email.placeholder')}
							type="email"
							validate={{
								required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
								minLength: { value: 5, errorMessage: translate('global.messages.validate.email.minlength') },
								maxLength: { value: 254, errorMessage: translate('global.messages.validate.email.maxlength') },
							}}
						/>
						<AvField
							value={pw}
							name="firstPassword"
							label={"We generated a password for you: "+pw }
							placeholder={translate('global.form.newpassword.placeholder')}
							type="password"
							onChange={updatePassword}
							validate={{
								required: { value: true, errorMessage: translate('global.messages.validate.newpassword.required') },
								minLength: { value: 4, errorMessage: translate('global.messages.validate.newpassword.minlength') },
								maxLength: { value: 50, errorMessage: translate('global.messages.validate.newpassword.maxlength') },
							}}
						/>
					
						<Button id="register-submit" color="primary" type="submit">
							<Translate contentKey="register.form.button">Register</Translate>
						</Button>
					</AvForm>
					<p>&nbsp;</p>
					<Alert color="warning">
						<span>
							<Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>
						</span>
						<a className="alert-link">
							<Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>
						</a>
						<span>
							<Translate contentKey="global.messages.info.authenticated.suffix">
								, you can try the default accounts:
                <br />- Administrator (login=&quot;admin&quot; and password=&quot;admin&quot;)
                <br />- User (login=&quot;user&quot; and password=&quot;user&quot;).
              </Translate>
						</span>
					</Alert>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = ({ locale }: IRootState) => ({
	currentLocale: locale.currentLocale,
});

const mapDispatchToProps = { handleRegister, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
