import { Moment } from 'moment';
import { IInteractionContext } from 'app/shared/model/interaction-context.model';
import { Origin } from 'app/shared/model/enumerations/origin.model';

export interface ITopic {
  id?: number;
  value?: string;
  rank?: number;
  timestamp?: string;
  origin?: Origin;
  interactionContext?: IInteractionContext;
}

export const defaultValue: Readonly<ITopic> = {};
