import React, { useEffect } from 'react';
import { getGitpull } from '../administration.reducer';
export const Gitpull= () => {

	useEffect(() => {
		getGitpull();
		console.log("asd")
	}, []);



	return (
		<div>
			<h2 >Git pull started
			</h2>
		</div>
	);
};

export default Gitpull;