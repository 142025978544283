import { Moment } from 'moment';
import { IInteractionContext } from 'app/shared/model/interaction-context.model';

export interface IQuery {
  id?: number;
  value?: string;
  rank?: number;
  timestamp?: string;
  interactionContext?: IInteractionContext;
}

export const defaultValue: Readonly<IQuery> = {};
