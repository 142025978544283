import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import InteractionContext from './interaction-context';
import InteractionContextDetail from './interaction-context-detail';
import InteractionContextUpdate from './interaction-context-update';
import InteractionContextDeleteDialog from './interaction-context-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={InteractionContextUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={InteractionContextUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={InteractionContextDetail} />
      <ErrorBoundaryRoute path={match.url} component={InteractionContext} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={InteractionContextDeleteDialog} />
  </>
);

export default Routes;
