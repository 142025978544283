import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Search from 'app/modules/search/search';
import About from 'app/modules/about/about';
import Imprint from 'app/modules/imprint/imprint';
import Reindex from 'app/modules/administration/reindex/reindex';
import Gitpull from 'app/modules/administration/gitpull/gitpull';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';

const Account = Loadable({
	loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
	loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
	loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
	loading: () => <div>loading ...</div>,
});

const Routes = () => (
	<div className="view-routes">
		<Switch>
			<ErrorBoundaryRoute path="/login" component={Login} />
			<ErrorBoundaryRoute path="/logout" component={Logout} />
			<ErrorBoundaryRoute path="/account/register" component={Register} />
			<ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
			<ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
			<ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
			<PrivateRoute path="/reindex/reindex" component={Reindex} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
			<PrivateRoute path="/git/pull" component={Gitpull} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
			<PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
			<PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
			<ErrorBoundaryRoute path="/" exact component={Search} />
			<ErrorBoundaryRoute path="/search" exact component={Search} />
			<ErrorBoundaryRoute path="/about" component={About} />
			<ErrorBoundaryRoute path="/imprint" component={Imprint} />
			<PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />
			<ErrorBoundaryRoute component={PageNotFound} />
		</Switch>
	</div>
);

export default Routes;
