import * as React from "react";

import * as ReactDOM from "react-dom";
import * as _ from 'lodash';
import { Col, Row } from 'reactstrap';

import parse from 'html-react-parser';
/* eslint no-console: 0  no-dupe-keys: 0 */



export const langCountryCodes = [
	"en", "de"];


export const langCodes = [
	"de",
	"en",
];

export const languageNames = {
	"all": "All languages",
	"en": "English",
	"de": "German"
}



export function imtAligned(doc) {
	const htm = [];
	const htm2 = [];
	console.info(doc.imtwordsbare.length);

	for (let i = 0; i < doc.srcwordsbare.length; i++) {
		htm.push(<Col key={'sb-' + i}> {parse(doc.srcwordsbare[i])}</Col>)
	}
	for (let i = 0; i < doc.imtwordsbare.length; i++) {
		htm2.push(<Col key={'ib-' + i}> {parse(doc.imtwordsbare[i] + '&nbsp;')}</Col>);
	}

	return <div className="imt"><Row>{htm}</Row><Row>{htm2}</Row></div>;
}

export function langNameLinked(hitSource, withHeader) {
	const htm = [];
	if (hitSource.language_name === undefined || hitSource.language_name === "") {
		return "";
	} else {
		if(withHeader){
			htm.push(<h5>Language</h5>);
		}else{
			
			htm.push(<span>Language: </span>);
		}
		if (hitSource.language_glottocode === undefined || hitSource.language_glottocode === "") {
			htm.push(hitSource.language_name);
		} else {
			htm.push(<a href={'https://glottolog.org/resource/languoid/id/' + hitSource.language_glottocode}> {hitSource.language_name}</a >);
		}
	}
	return htm;
}

export function citation(hitSource) {
	const htm = [];
	if (hitSource.citation === undefined || hitSource.citation === "") {
		return "";
	} else {
		htm.push(<span>Citation: </span>);
		htm.push(hitSource.citation);
	}
	return htm;
}


export function bookLink(src) {
	const htm = [];
	if (src.doc_ID === undefined || src.doc_ID === "") {
		return "";
	} else {
		htm.push(<h5>LangSci Press publication</h5>)
		htm.push(<a href={'https://langsci-press.org/catalog/book/' + src.doc_ID}>{src?.book_title} 	<br /></a>);
	}
	return htm;
}




export function txt2Html(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			htm.push(<span key={'hl-' + i}> {parse(data[i] + '&nbsp;')}</span>);
		}
		return htm;
	}

}


export function txt2HtmlTitled(data, title) {

	const htm = [];
	if (data === undefined || data.length === 0) {
		return "";
	} else {
		htm.push(<h5 > {title}</h5>);

		htm.push(<span >{data}<br /></span>);


	}
	return htm;

}
export function txt2list(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			htm.push(<li key={'hl-' + i}> {parse(data[i] + '&nbsp;')}</li>);
		}
		return <ul> {htm} </ul>;
	}

}
export function txt2HtmlBr(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			let s = <span key={'hl-' + i}> {parse(data[i] + '&nbsp;')}<br /></span>;
			if (data[i].startsWith("http")) {
				s = <a href={data[i]}>{s}</a>;
			}
			htm.push(s);
		}
		return htm;
	}

}






