import './imprint.scss';

import React from 'react';
import { Row, Col } from 'reactstrap';



export const Imprint = () => {

	return (
		<>
		<Row>
			<Col md="3" className="pad">
			</Col>
			<Col md="6">
				<h1>Imprint</h1> 
				<p>LangSci Press gUG (haftungsbeschränkt)</p>
				<p>Bänschstraße 29, 10247 Berlin</p>
				<p>Geschäftsführer Dr. Sebastian Nordhoff</p>
				<p>Amtsgericht Berlin-Charlottenburg</p>
				<p>Handelsregister HRB 188251 B</p>
			</Col>
			<Col md="3" className="pad">
			</Col>
		</Row>
		<Row>
			<Col md="3" className="pad">
			</Col>
			<Col md="6">
				<h1>All content <a href="https://creativecommons.org/licenses/by/4.0/deed.de">CC BY 4.0</a></h1>
			</Col>
			<Col md="3" className="pad">
			</Col>
		</Row>
		</>
	);
};



export default (Imprint);
