import { IUser } from 'app/shared/model/user.model';
import { IQuery } from 'app/shared/model/query.model';
import { ITopic } from 'app/shared/model/topic.model';
import { IKeyword } from 'app/shared/model/keyword.model';

export interface IInteractionContext {
  id?: number;
  session?: string;
  user?: IUser;
  queries?: IQuery[];
  topics?: ITopic[];
  keywords?: IKeyword[];
}

export const defaultValue: Readonly<IInteractionContext> = {};
