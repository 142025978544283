import axios from 'axios';
import {
  ICrudSearchAction,
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInteractionContext, defaultValue } from 'app/shared/model/interaction-context.model';

export const ACTION_TYPES = {
  SEARCH_INTERACTIONCONTEXTS: 'interactionContext/SEARCH_INTERACTIONCONTEXTS',
  FETCH_INTERACTIONCONTEXT_LIST: 'interactionContext/FETCH_INTERACTIONCONTEXT_LIST',
  FETCH_INTERACTIONCONTEXT: 'interactionContext/FETCH_INTERACTIONCONTEXT',
  FETCH_MY_INTERACTIONCONTEXT: 'interactionContext/FETCH_MY_INTERACTIONCONTEXT',
  CREATE_INTERACTIONCONTEXT: 'interactionContext/CREATE_INTERACTIONCONTEXT',
  UPDATE_INTERACTIONCONTEXT: 'interactionContext/UPDATE_INTERACTIONCONTEXT',
  DELETE_INTERACTIONCONTEXT: 'interactionContext/DELETE_INTERACTIONCONTEXT',
  RESET: 'interactionContext/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInteractionContext>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InteractionContextState = Readonly<typeof initialState>;

// Reducer

export default (state: InteractionContextState = initialState, action): InteractionContextState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_INTERACTIONCONTEXTS):
    case REQUEST(ACTION_TYPES.FETCH_INTERACTIONCONTEXT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INTERACTIONCONTEXT):
    case REQUEST(ACTION_TYPES.FETCH_MY_INTERACTIONCONTEXT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_INTERACTIONCONTEXT):
    case REQUEST(ACTION_TYPES.UPDATE_INTERACTIONCONTEXT):
    case REQUEST(ACTION_TYPES.DELETE_INTERACTIONCONTEXT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_INTERACTIONCONTEXTS):
    case FAILURE(ACTION_TYPES.FETCH_INTERACTIONCONTEXT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INTERACTIONCONTEXT):
    case FAILURE(ACTION_TYPES.FETCH_MY_INTERACTIONCONTEXT):
    case FAILURE(ACTION_TYPES.CREATE_INTERACTIONCONTEXT):
    case FAILURE(ACTION_TYPES.UPDATE_INTERACTIONCONTEXT):
    case FAILURE(ACTION_TYPES.DELETE_INTERACTIONCONTEXT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_INTERACTIONCONTEXTS):
    case SUCCESS(ACTION_TYPES.FETCH_INTERACTIONCONTEXT_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_INTERACTIONCONTEXT):
    case SUCCESS(ACTION_TYPES.FETCH_MY_INTERACTIONCONTEXT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INTERACTIONCONTEXT):
    case SUCCESS(ACTION_TYPES.UPDATE_INTERACTIONCONTEXT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_INTERACTIONCONTEXT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlIC = 'api/my-interaction-context';

const apiUrl = 'api/interaction-contexts';
const apiSearchUrl = 'api/_search/interaction-contexts';

// Actions

export const getSearchEntities: ICrudSearchAction<IInteractionContext> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_INTERACTIONCONTEXTS,
  payload: axios.get<IInteractionContext>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IInteractionContext> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INTERACTIONCONTEXT_LIST,
    payload: axios.get<IInteractionContext>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IInteractionContext> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  console.log(requestUrl);
  return {
    type: ACTION_TYPES.FETCH_INTERACTIONCONTEXT,
    payload: axios.get<IInteractionContext>(requestUrl),
  };
};


export const getMyInteractionContext: ICrudGetAction<any> = () => {
  const requestUrl = `${apiUrlIC}`;
  console.log(requestUrl);
  return {
    type: ACTION_TYPES.FETCH_MY_INTERACTIONCONTEXT,
    payload: axios.get<any>(requestUrl),
  };
};
export const getInteractionContext: ICrudGetAction<any> = id => {
  const requestUrl = `${apiUrlIC}/${id}`;
  console.log(requestUrl);
  return {
    type: ACTION_TYPES.FETCH_INTERACTIONCONTEXT,
    payload: axios.get<any>(requestUrl),
  };
};


export const createEntity: ICrudPutAction<IInteractionContext> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INTERACTIONCONTEXT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IInteractionContext> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INTERACTIONCONTEXT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IInteractionContext> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INTERACTIONCONTEXT,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
