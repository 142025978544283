import React, { useContext, useEffect, useState } from 'react';
import ISSHitItem from "./search-item";
/* eslint no-console: 0 */

import { connect } from 'react-redux';


function ResultListComponent(props) {


	return (
		<div>

			{props.hits.map((hit, index) =>
				<ISSHitItem key={index} result={hit} />
			)}</div>
	);
}
export default ResultListComponent;