import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, reset } from './interaction-context.reducer';
import { IInteractionContext } from 'app/shared/model/interaction-context.model';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export interface IInteractionContextProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const InteractionContext = (props: IInteractionContextProps) => {
	const [search, setSearch] = useState('');
	const [paginationState, setPaginationState] = useState(
		overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
	);
	const [sorting, setSorting] = useState(false);

	const getAllEntities = () => {
		if (search) {
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		} else {
			props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
		}
	};

	const resetAll = () => {
		props.reset();
		setPaginationState({
			...paginationState,
			activePage: 1,
		});
		props.getEntities();
	};

	useEffect(() => {
		resetAll();
	}, []);

	const startSearching = () => {
		if (search) {
			props.reset();
			setPaginationState({
				...paginationState,
				activePage: 1,
			});
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		}
	};

	const clear = () => {
		props.reset();
		setSearch('');
		setPaginationState({
			...paginationState,
			activePage: 1,
		});
		props.getEntities();
	};

	const handleSearch = event => setSearch(event.target.value);

	useEffect(() => {
		if (props.updateSuccess) {
			resetAll();
		}
	}, [props.updateSuccess]);

	useEffect(() => {
		getAllEntities();
	}, [paginationState.activePage]);

	const handleLoadMore = () => {
		if ((window as any).pageYOffset > 0) {
			setPaginationState({
				...paginationState,
				activePage: paginationState.activePage + 1,
			});
		}
	};

	useEffect(() => {
		if (sorting) {
			getAllEntities();
			setSorting(false);
		}
	}, [sorting, search]);

	const sort = p => () => {
		props.reset();
		setPaginationState({
			...paginationState,
			activePage: 1,
			order: paginationState.order === 'asc' ? 'desc' : 'asc',
			sort: p,
		});
		setSorting(true);
	};

	const { interactionContextList, match, loading } = props;
	return (
		<div>
			<h2 id="interaction-context-heading">
				<Translate contentKey="issApp.interactionContext.home.title">Interaction Contexts</Translate>
				<Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
					<FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="issApp.interactionContext.home.createLabel">Create new Interaction Context</Translate>
				</Link>
			</h2>
			<Row>
				<Col sm="12">
					<AvForm onSubmit={startSearching}>
						<AvGroup>
							<InputGroup>
								<AvInput
									type="text"
									name="search"
									value={search}
									onChange={handleSearch}
									placeholder={translate('issApp.interactionContext.home.search')}
								/>
								<Button className="input-group-addon">
									<FontAwesomeIcon icon="search" />
								</Button>
								<Button type="reset" className="input-group-addon" onClick={clear}>
									<FontAwesomeIcon icon="trash" />
								</Button>
							</InputGroup>
						</AvGroup>
					</AvForm>
				</Col>
			</Row>
			<div className="table-responsive">
				<InfiniteScroll
					pageStart={paginationState.activePage}
					loadMore={handleLoadMore}
					hasMore={paginationState.activePage - 1 < props.links.next}
					loader={<div className="loader">Loading ...</div>}
					threshold={0}
					initialLoad={false}
				>
					{interactionContextList && interactionContextList.length > 0 ? (
						<Table responsive>
							<thead>
								<tr>
									<th className="hand" onClick={sort('id')}>
										<Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
									</th>
									<th className="hand" onClick={sort('session')}>
										<Translate contentKey="issApp.interactionContext.session">Session</Translate> <FontAwesomeIcon icon="sort" />
									</th>
									<th>
										<Translate contentKey="issApp.interactionContext.user">User</Translate> <FontAwesomeIcon icon="sort" />
									</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{interactionContextList.map((interactionContext, i) => (
									<tr key={`entity-${i}`}>
										<td>
											<Button tag={Link} to={`${match.url}/${interactionContext.id}`} color="link" size="sm">
												{interactionContext.id}
											</Button>
										</td>
										<td>{interactionContext.session}</td>
										<td>{interactionContext.user ? interactionContext.user.login : ''}</td>
										<td className="text-right">
											<div className="btn-group flex-btn-group-container">
												<Button tag={Link} to={`${match.url}/${interactionContext.id}`} color="info" size="sm">
													<FontAwesomeIcon icon="eye" />{' '}
													<span className="d-none d-md-inline">
														<Translate contentKey="entity.action.view">View</Translate>
													</span>
												</Button>
												<Button tag={Link} to={`${match.url}/${interactionContext.id}/edit`} color="primary" size="sm">
													<FontAwesomeIcon icon="pencil-alt" />{' '}
													<span className="d-none d-md-inline">
														<Translate contentKey="entity.action.edit">Edit</Translate>
													</span>
												</Button>
												<Button tag={Link} to={`${match.url}/${interactionContext.id}/delete`} color="danger" size="sm">
													<FontAwesomeIcon icon="trash" />{' '}
													<span className="d-none d-md-inline">
														<Translate contentKey="entity.action.delete">Delete</Translate>
													</span>
												</Button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					) : (
							!loading && (
								<div className="alert alert-warning">
									<Translate contentKey="issApp.interactionContext.home.notFound">No Interaction Contexts found</Translate>
								</div>
							)
						)}
				</InfiniteScroll>
			</div>
		</div>
	);
};

const mapStateToProps = ({ interactionContext }: IRootState) => ({
	interactionContextList: interactionContext.entities,
	loading: interactionContext.loading,
	totalItems: interactionContext.totalItems,
	links: interactionContext.links,
	entity: interactionContext.entity,
	updateSuccess: interactionContext.updateSuccess,
});

const mapDispatchToProps = {
	getSearchEntities,
	getEntities,
	reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InteractionContext);
