import './about.scss';

import React from 'react';
import { Row, Col, Alert } from 'reactstrap';



export const About = () => {

	return (
		<Row>
			<Col md="3" className="pad">
			</Col>
			<Col md="6">
				<h1 id="url-resolver">URL Resolver</h1>
				<p>imtvault includes a URL resolver, that</p>
				<ul>
					<li><p>resolves a book ID to its landing page on the language science press website</p>
						<p>The pattern is <a href="/b/270">http://imtvault.org/b/270</a></p>
					</li>
					<li><p>resolves all examples belonging to a book ID as JSON </p>
						<p>The pattern is <a href="/b/270.json">http://imtvault.org/b/270.json</a></p>
					</li>
					<li><p>resolves all examples belonging to a book ID as simple HTML</p>
						<p>The pattern is <a href="/b/270.htm">http://imtvault.org/b/270.htm</a></p>
					</li>
					<li><p>resolves one particular example as JSON </p>
						<p>The pattern is <a href="/b/270/ex/langsci270-0b5549eb6f.json">http://imtvault.org/b/270/ex/langsci270-0b5549eb6f.json</a></p>
					</li>
					<li><p>resolves one particular example as JSON LD 1.1 </p>
						<p>The pattern is <a href="/b/270/ex/langsci270-0b5549eb6f.jsonld">http://imtvault.org/b/270/ex/langsci270-0b5549eb6f.jsonld</a></p>
					</li>
						<li><p>resolves one particular example as N-Quads </p>
						<p>The pattern is <a href="/b/270/ex/langsci270-0b5549eb6f.nq">http://imtvault.org/b/270/ex/langsci270-0b5549eb6f.nq</a></p>
					</li>
					<li><p>resolves one particular example as simple HTML</p>
						<p>The pattern is <a href="/b/270/ex/langsci270-0b5549eb6f.htm">http://imtvault.org/b/270/ex/langsci270-0b5549eb6f.htm</a></p>
					</li>
				</ul>
				<h1 id="api-usage">API usage</h1>
				<h2 id="query-language">Query language</h2>
				<p>imtvault uses elasticsearch as search engine.
					Basic search requests as well as filtering and sorting is supported. </p>

				<p>You can formulate complex queries satisfying your requirements by simply adapting the HTTP POST raw data JSON part.
					<br />Please refer to the <a href="https://www.elastic.co/guide/en/elasticsearch/reference/6.8/full-text-queries.html">elasticsearch documentation for details on the query syntax</a>. </p>

			</Col>
			<Col md="3" className="pad">
			</Col>
		</Row>
	);
};



export default (About);
