import './search.scss';
import './customStyle.scss';
import React, { useRef, useContext, useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Helmet } from "react-helmet";
import ResultListComponent from "./resultList-component";

/* eslint  no-console: 0 */
import { getMyInteractionContext } from '../../entities/interaction-context/interaction-context.reducer';

import {
	PageSizeSelector, InputFilter, Toggle, SortingSelector,
	HitsStats, SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, SearchBox, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect
} from "searchkit";
import appConfig from 'app/config/constants';


const host = appConfig.ELASTICSEARCH_URL;
const searchkitManager = new SearchkitManager(host, { timeout: 20000 });

function Search(props) {
	const sbox = useRef();
	const [ic, setIc] = useState(undefined);



	const nested = (query, options) => {

		if (!query) return {}



		const fields = [];

		const newOptionsFields = []
		options.fields.map(f => {
			const s = f.replace(".undefined", "")
			newOptionsFields.push(s)
		})
		options.fields = ["*"]


		let json = {};
		json = {
			"bool": {
				"should": [
					{ "multi_match": { query, fields } }
				]
			}
		}
		return json;
	}




	if (props.location.search !== undefined && props.location.search !== "") {
		console.info(props.location.search.substring(3))
		console.info(sbox.current);
		console.info(props.location)


	}


	useEffect(() => {
		const promise: any = getMyInteractionContext(0);
		const ppromise = promise.payload;
		ppromise.then(v => {
			console.info(v.data);
			setIc(v.data);
		});
	}, [])

	return (
		<SearchkitProvider searchkit={searchkitManager}>
			<Layout size="l" className="search" id="search">
				<Helmet>
					<title>IMT Vault</title>
				</Helmet>
				<Container>
					<Row>
						<Col md="3">
						</Col>
						<Col md="">
							<SearchBox autofocus={true} searchOnChange={true} searchThrottleTime={400} ref={sbox} queryBuilder={nested} />
							<ResetFilters translations={{ "reset.clear_all": "Clear search" }} options={{ query: true, filter: true, pagination: true }} />
						</Col>
						<Col md="3">

						</Col>
					</Row>
				</Container>
				<Container>
					<LayoutBody className="row">
						<Col md="2">
							<SideBar >
								<HitsStats />
								<h6>Page size </h6>
								<PageSizeSelector options={[5, 10, 25]} listComponent={Toggle} />
								<h6>Sorting</h6>
								<SortingSelector options={[
									{ label: "Relevance", field: "_score", order: "desc", defaultOption: true },
									{ label: "Language name", field: "language_name.keyword", order: "desc" },
									{ label: "Character length desc", field: "clength", order: "desc" },
									{ label: "Character length asc", field: "clength", order: "asc" },
									{ label: "Word length desc", field: "wlength", order: "desc" },
									{ label: "Word length asc", field: "wlength", order: "asc" }
								]} /><br /><br />
								<GroupedSelectedFilters /><br />
								<h6>Search per field</h6>
								<InputFilter
									id="srcwordsbare"
									title="Vernacular text"
									placeholder="Search vernacular text"
									searchOnChange={true}
									prefixQueryFields={["srcwordsbare"]}
									queryFields={["srcwordsbare"]}
								/>
								<InputFilter
									id="trs"
									title="Translation"
									placeholder="Search translation"
									searchOnChange={true}
									prefixQueryFields={["trs"]}
									queryFields={["trs"]}
								/><RangeFilter field="clength" id="crlength" min={2} max={80} showHistogram={true} title="Length (characters)" />
								<RangeFilter field="wlength" id="wrlength" min={0} max={16} showHistogram={true} title="Length (words)" />

							</SideBar>
						</Col>
						<Col md="2">
							<SideBar >
								<h6>Filters</h6>
								<RefinementListFilter
									id="languageiso6393"
									title="Language iso6393 	"
									field={'language_iso6393.keyword'}
									size={3} orderDirection="asc" orderKey="_term"
									operator={'AND'} />

								<RefinementListFilter
									id="languageName"
									title="Language name	"
									field={'language_name.keyword'}
									size={3} orderDirection="asc" orderKey="_term"
									operator={'AND'} />



									
								<RefinementListFilter
									id="editor"
									title="Editor"
									field={'provider.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								<RefinementListFilter
									id="parententities"
									title="Parent concepts"
									field={'parententities.label.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								<RefinementListFilter
									id="entities"
									title="Concepts "
									field={'entities.label.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								<RefinementListFilter
									id="cat"
									title="Categories	"
									field={'categories.keyword'}
									size={3} orderDirection="asc"
									operator={'AND'}  />
								<div className={(ic?.user?.login === "admin") ? "xx" : "hideForUser"}>
									<RefinementListFilter

										id="doc_ID"
										title="Book ID"
										field={'doc_ID.keyword'}
										size={3} orderDirection="asc"
										operator={'AND'} />
								</div>
								<RefinementListFilter
									id="bookTitle"
									title="Book"
									field={'book_title.keyword'}
									size={3} orderDirection="asc"
									operator={'AND'} />
							</SideBar>
						</Col>
						<Col md="8">
							<LayoutResults>
								<Hits hitsPerPage={5} mod="sk-hits-list" className="column" listComponent={<ResultListComponent />} />
								<Row>
									<Col md="12">
										<Pagination pageScope={5} showLast={true} showNumbers={true} />

									</Col>
								</Row>
							</LayoutResults>
						</Col>

					</LayoutBody>
				</Container>
			</Layout>
		</SearchkitProvider>
	);
}

export default Search;
