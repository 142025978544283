import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity , getInteractionContext} from './interaction-context.reducer';

import { ITopic } from 'app/shared/model/topic.model';
import { IInteractionContext } from 'app/shared/model/interaction-context.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IInteractionContextDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const InteractionContextDetail = (props: IInteractionContextDetailProps) => {
	useEffect(() => {
		props.getEntity(props.match.params.id);
		props.getInteractionContext(props.match.params.id);
	}, []);

	const { interactionContextEntity } = props;
	console.log(interactionContextEntity);
	return (
		<Row>
			<Col md="8">
				<h2>
					<Translate contentKey="issApp.interactionContext.detail.title">InteractionContext</Translate> [
          <b>{interactionContextEntity.id}</b>]
        </h2>
				<dl className="jh-entity-details">
					<dt>
						<span id="session">
							<Translate contentKey="issApp.interactionContext.session">Session</Translate>
						</span>
					</dt>
					<dd>{interactionContextEntity.session}</dd>
					<dt>
						<Translate contentKey="issApp.interactionContext.user">User</Translate>
					</dt>
					<dd>{interactionContextEntity.user ? interactionContextEntity.user.login : ''}</dd>
					<dt>
						Queries
          </dt>
					<dd>{interactionContextEntity?.queries?.map((query, i) => (
						<div key={`query-${i}`}>
							{query.value}
							{query.rank}
							{query.timestamp}
						</div>
					))}</dd>
					<dt>
						Keywords
          </dt>
					<dd>{interactionContextEntity?.keywords?.map((keywords, i) => (
						<div key={`keywords-${i}`}>
							{keywords.value}
							{keywords.rank}
							{keywords.timestamp}
						</div>
					))}</dd>
					<dt>
						Topics
					</dt>
					<dd>
						{interactionContextEntity?.topics?.map((topics, i) => (
							<div key={`topics-${i}`}>
								{topics.value}
								{topics.rank}
								{topics.timestamp}
							</div>
						))}

					</dd>
				</dl>
				<Button tag={Link} to="/interaction-context" replace color="info">
					<FontAwesomeIcon icon="arrow-left" />{' '}
					<span className="d-none d-md-inline">
						<Translate contentKey="entity.action.back">Back</Translate>
					</span>
				</Button>
        &nbsp;
        <Button tag={Link} to={`/interaction-context/${interactionContextEntity.id}/edit`} replace color="primary">
					<FontAwesomeIcon icon="pencil-alt" />{' '}
					<span className="d-none d-md-inline">
						<Translate contentKey="entity.action.edit">Edit</Translate>
					</span>
				</Button>
			</Col>
		</Row>
	);
};

const mapStateToProps = ({ interactionContext }: IRootState) => ({
	interactionContextEntity: interactionContext.entity,
});

const mapDispatchToProps = { getEntity, getInteractionContext };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InteractionContextDetail);
